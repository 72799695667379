<template>
  <v-app>
    <div class="padding-card-c grey">
      <v-card>
        <div class="padding-card-c">
          <p class="title-user-scr-b">Reglamentos</p>
          <RulesForm @oncreate="getRules" />
        </div>
      </v-card>
      <p class="title-user-scr-b" style="padding-top: 50px">Reglamentos vigentes</p>
      <v-col v-for="(item) in shownCards" :key="item.id" cols="12">
        <v-card v-if="item" color="white">
          <div class="d-flex flex-no-wrap justify-space-between aling-items-center">
            <v-list-item>  
              <v-list-item-content style="margin-left: 15px">
                <v-list-item-title class="title-text-info">{{
                  item.title.split(' ').slice(0, 10).join(' ') + '...'
                }}</v-list-item-title>
                 <v-list-item-subtitle>{{
                  item.kind }}</v-list-item-subtitle>                
                <span style="font-size: 12px"> {{ $dayjs(item.createdAt).format('lll') }} </span>                
                <span v-if="item.deleted" style="font-size: 12px; color:#f00">Deleted</span>
              </v-list-item-content>             
            </v-list-item>

            <v-list-item-action>    
              <div style="display: flex">           
                <div style="padding-top: 10px; padding-right: 5px" >                                
                  <v-btn v-if="!item.deleted" color="primary" small fab dark @click="confirmDeletion(item.id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>  
                </div>     
                <div style="padding-top: 10px; margin-right:20px"> 
                  <modal-edit-rule :rule="item" :id="item.id" @onUpdate="getRules"></modal-edit-rule>   
                </div>
              </div>                
            </v-list-item-action> 
                    
          </div>
        </v-card>
      </v-col>
       <div class="text-center">
      <v-pagination v-model="page" :length="length"></v-pagination>
    </div>
    </div>
  </v-app>
</template>

<script>
import adminRuleService from '@/services/admin/rules';
import RulesForm from '@/components/RulesForm.vue';
import ModalEditRule from '../../components/ModalEditRule.vue';

export default {
  props: {
    request: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    RulesForm, ModalEditRule
  },
  created() {
    this.getRules();   
  },
  methods: {
    getRules() {
      adminRuleService.fetch().then((response) => {
        this.rules = response.data;
      });
    },
   
    confirmDeletion(id) {
      this.$swal({
          title: 'Esta accion será irreversible',
          text: "Confirma la eliminación",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si quiero proceder',
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRule(id);            
          }
        });
      },
      deleteRule(id) {
        adminRuleService.deleteRule(id)
        .then((response) => {
          console.log(response.data);
          this.getRules();
          this.$swal.fire('¡Documento eliminado!', 'Se eliminó definitivamente.', 'success');         
        }).catch(err=>{
          this.$swal.fire('¡Error!', err, 'error');    
        });
      },    
  },
  data() {
    return {
      page:1,
      rules: [],
    };
  },

computed: {
    length() {
      return Math.ceil(this.rules.length / 5);
    },

    shownCards() {
      const { page, rules } = this;
      const number = 5; // Numero de elementos a mostrar por página
      return rules.slice((page - 1) * number, page * number);
    },
  },

};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>