<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-col cols="12">
          <v-btn color="primary" small fab dark v-bind="attrs" v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-col>
      </template>
      <v-card>        

        <v-card-title>Editar reglamento</v-card-title>

        <v-card-text class="text--primary">
          <v-form ref="formEditRules" v-model="validForm" lazy-validation>
          <v-text-field label="Título descriptivo" :rules="rules.title" v-model="title"></v-text-field>    
          <v-autocomplete
                  dense                  
                  return-object     
                  v-model="kind"         
                  :items="['Reglamentos', 'Asambleas', 'Reglas de uso']"
                  filled
                  label="Tipo"
                >
              </v-autocomplete>  
          <v-row>   
            <v-file-input ref="uploadPdf" 
              @change="selectPdf" 
              :hint="fileName"
              persistent-hint
              accept="application/pdf"
              label="Seleccionar Pdf"></v-file-input>
          </v-row>
          <v-btn @click="alertruleupdate" depressed color="#00aff2" dark style="margin-top:30px"> Actualizar </v-btn>
        </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import adminRuleService from '@/services/admin/rules';

export default {
  props: {
    rule: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: Number,
      required: true,
    }
  },
  components: {},
  data: () => ({
    dialog: false,
    validForm: true,
    rules:{
        required: (value) => !!value || 'Requerido.',
        title: [
          (v) => !!v || 'Titulo requerido',
          (v) => (v && v.length <= 34) || 'Nombre debe ser menor a 34 caracteres',
          (v) => (v && v.length >= 2) || 'Nombre debe ser mayor a 2 caracteres',
        ],
    },
    title: '', 
    kind: 'Reglamentos',    
    pdf: null,
    fileName:'',
  }),
  created(){
    this.title= this.rule.title;   
    this.kind= this.rule.kind; 
    this.fileName= this.rule.pdfName.split('/').pop();
  },
  methods: {   
    selectPdf(file) {
      this.pdf = file;
      console.log(file);
      this.fileName = '';
    },
    alertruleupdate() {
      if (this.$refs.formEditRules.validate()) {
        const formData = new FormData();
        formData.append('title', this.title); 
        formData.append('kind', this.kind);   
        formData.append('id', this.id);           
        if (this.pdf !== null && this.pdf != undefined && this.pdf != '') formData.append('pdf', this.pdf, this.pdf.name);
        adminRuleService
          .update(formData)
          .then(() => {                   
            this.$emit('onUpdate');
            this.$swal('Archivo publicado', 'El reglamento se ha modificado con éxito', 'success');
            this.$emit('exit', true);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.$swal('Error al crear publicación', error.response.data.message, 'warning');
            }
            if (error.response && error.response.status === 413) {
              this.$swal('Error al crear publicación', error.response.data.message, 'warning');
            }
          });        
      }
    },
  }};

</script>