<template>
  <v-form ref="formRules" v-model="validForm" lazy-validation>
    <v-text-field label="Título descriptivo" :rules="rules.title" v-model="title"></v-text-field>    
    <v-autocomplete
            dense                  
            return-object     
            v-model="kind"         
            :items="['Reglamentos', 'Asambleas', 'Reglas de uso']"
            filled
            label="Tipo"
          >
        </v-autocomplete>  
    <v-row>   
      <v-file-input ref="uploadPdf" 
        @change="selectPdf" 
        accept="application/pdf"
        :rules="[rules.required]" 
        label="Seleccionar Pdf"></v-file-input>
    </v-row>
    <v-btn @click="alertrulespublish" depressed color="#00aff2" dark> Publicar </v-btn>
  </v-form>
</template>

<script>
import adminRuleService from '@/services/admin/rules';

export default {
  data() {
    return {
      validForm: true,
      rules:{
          required: (value) => !!value || 'Requerido.',
          title: [
            (v) => !!v || 'Titulo requerido',
            (v) => (v && v.length <= 34) || 'Nombre debe ser menor a 34 caracteres',
            (v) => (v && v.length >= 2) || 'Nombre debe ser mayor a 2 caracteres',
          ],
      },
      title: '', 
      kind: 'Reglamentos',    
      pdf: null,
    };
  },
  methods: {   
    selectPdf(file) {
      this.pdf = file;
      console.log(this.pdf);
    },
    alertrulespublish() {
      if (this.$refs.formRules.validate()) {
        const formData = new FormData();
        formData.append('title', this.title); 
        formData.append('kind', this.kind);                  
        if (this.pdf !== null && this.pdf != undefined && this.pdf != '') formData.append('pdf', this.pdf, this.pdf.name);
        adminRuleService
          .create(formData)
          .then(() => {
            this.title = '';
            this.pdf = null;
            this.kind = 'Reglamentos';            
            this.$emit('oncreate');
            this.$swal('Archivo publicado', 'El reglamento se ha publicado con éxito', 'success');
            this.$emit('exit', true);
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.$swal('Error al crear publicación', error.response.data.message, 'warning');
            }
            if (error.response && error.response.status === 413) {
              this.$swal('Error al crear publicación', error.response.data.message, 'warning');
            }
          });
      }
    },
  },
};
</script>